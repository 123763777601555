<template>
  <div class="vista-detalles paquete">
    <div class="container-xl px-0">
      <div
        v-if="!$apolloData.queries.paquete.loading"
        class="row align-items-center justify-content-center mt-3"
      >
        <mdb-btn
          flat
          dark-waves
          icon="angle-left"
          icon-class="fa-lg"
          class="col-auto btn-bordeado px-lg-3"
          @click="$emit('actualizar', paquete); $router.go(-1)"
        >
          Volver atrás
        </mdb-btn>
        <div
          :class="[paquete ? 'col-auto': 'd-none d-md-block col-3',
            'order-md-3 botonFlotante']"
        >
          <mdb-btn
            v-if="paquete"
            color="primario"
            :disabled="!paquete"
            icon="images"
            @click="obtenerFotosPaquete"
          >
            Ver fotos
          </mdb-btn>
        </div>
        <h3 class="col-12 col-md order-md-2 mt-2 text-center user-select-none">
          Paquete
        </h3>
      </div>
      <CargandoVista
        v-if="$apolloData.queries.paquete.loading"
        clase-adicional="vc-75vh"
      />
      <div
        v-else-if="!$apolloData.queries.paquete.loading && !paquete"
        class="vertical-centered vc-50vh user-select-none"
      >
        <font-awesome-icon
          :icon="['fas', 'folder-open']"
          class="fa-5x my-4"
        />
        <p class="h3-responsive text-center mb-0">
          El paquete solicitado no existe
        </p>
      </div>
      <div v-else class="mb-page">
        <div class="row mt-md-2">
          <p class="col-12 col-md-4 col-xl-3 mb-2 pr-lg-0">
            <small class="text-muted user-select-none d-block">
              Estatus
            </small>
            <img
              v-if="paquete.estatus && paquete.estatus.length"
              :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
              alt="Icono"
              height="18"
              width="18"
            >
            <span :class="{'text-muted font-italic user-select-none': !(paquete.estatus && paquete.estatus.length)}">
              {{ paquete.estatus && paquete.estatus.length ? PaqueteEstatus[paquete.estatus] : "Sin especificar" }}
            </span>
          </p>
          <p class="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Paquete ID
            </small>
            <span :class="{'text-muted font-italic user-select-none': !paquete.numeroHR}">
              {{ paquete.numeroHR ? paquete.numeroHR : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Tipo
            </small>
            <img
              :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
              alt="Icono"
              height="18"
              width="18"
            >
            {{ paquete.tipo }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Empaque
            </small>
            <font-awesome-icon
              v-if="paquete.empaque"
              :icon="PaqueteTipoEmpaqueIconos[paquete.empaque]"
              class="texto-terciario"
            />
            <span
              v-else
              class="text-muted font-italic user-select-none d-block"
            >
              Sin especificar
            </span>
            {{ paquete.empaque }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Alto
            </small>
            <span
              :class="{'text-muted font-italic user-select-none': !paquete.alto}"
              title="Pulgadas"
            >
              {{ paquete.alto ? paquete.alto + '" (in)' : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Ancho
            </small>
            <span
              :class="{'text-muted font-italic user-select-none': !paquete.ancho}"
              title="Pulgadas"
            >
              {{ paquete.ancho ? paquete.ancho + '" (in)'  : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Largo
            </small>
            <span
              :class="{'text-muted font-italic user-select-none': !paquete.largo}"
              title="Pulgadas"
            >
              {{ paquete.largo ? paquete.largo + '" (in)'  : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso
            </small>
            <span
              :class="{'text-muted font-italic user-select-none': !paquete.peso}"
              title="Libras"
            >
              {{ paquete.peso ? paquete.peso + ' lb' : "Sin especificar" }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Asegurado
            </small>
            <font-awesome-icon
              :icon="paquete.asegurado ? 'check-circle' : 'times-circle'"
              :class="paquete.asegurado ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ paquete.asegurado ? "Si" : "No" }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Valor Declarado
            </small>
            <span v-if="paquete.valorDeclarado" title="Dólares">
              $ {{ paquete.valorDeclarado }}
            </span>
            <span v-else class="text-muted font-italic user-select-none">
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Pagado
            </small>
            <font-awesome-icon
              :icon="paquete.pagado ? 'check-circle' : 'times-circle'"
              :class="paquete.pagado ? 'texto-exitoso' : 'texto-peligro'"
            />
            {{ paquete.pagado ? "Si" : "No" }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Costo Final
            </small>
            <span v-if="paquete.costo" title="Dólares">
              $ {{ paquete.costo }}
            </span>
            <span v-else class="text-muted font-italic user-select-none">
              Sin especificar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Guía consolidada
            </small>
            <span
              v-if="paquete.consolidado"
              :class="{'text-muted font-italic user-select-none': !paquete.consolidado.master}"
            >
              {{ paquete.consolidado.master ? paquete.consolidado.master : 'Sin guía consolidada'}}
            </span>
            <span v-else class="text-muted font-italic user-select-none">
              Sin asignar
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Fecha
            </small>
            <font-awesome-icon
              icon="calendar-day"
              class="texto-terciario"
            />
            <span
              :class="{'text-muted font-italic user-select-none': !(paquete.fecha && paquete.fecha.formatted)}"
            >
              {{ paquete.fecha && paquete.fecha.formatted
                ? paquete.fecha.formatted : "Sin especificar"
              }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Arribo
            </small>
            <font-awesome-icon
              icon="calendar-day"
              class="texto-terciario"
            />
            <span
              :class="{'text-muted font-italic user-select-none': !(paquete.fechaArribo && paquete.fechaArribo.formatted)}"
            >
              {{ paquete.fechaArribo && paquete.fechaArribo.formatted
                ? paquete.fechaArribo.formatted : "Sin especificar"
              }}
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Defectuoso
            </small>
            <font-awesome-icon
              :icon="paquete.defectuoso ? 'times-circle' : 'check-circle'"
              :class="paquete.defectuoso ? 'texto-peligro' : 'texto-exitoso'"
            />
            {{ paquete.defectuoso ? "Si" : "No" }}
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Pies cúbicos
            </small>
            <span title="Pies cúbicos">
              {{
                mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
              }} ft<sup>3</sup>
            </span>
          </p>
          <p class="col-6 col-md-4 col-xl-3 mb-2">
            <small class="text-muted user-select-none d-block">
              Peso por volúmen
            </small>
            <span title="Libras">
              {{
                mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
              }} lb
            </span>
          </p>
          <p v-if="paquete.observacionEnvio" class="col-12 col-md mb-2">
            <small class="text-muted user-select-none d-block">
              Nota de entrega
            </small>
            {{ paquete.observacionEnvio }}
          </p>
        </div>
        <!-- Datos de recepcion si paquete.tipo === 'Individual' -->
        <div
          v-if="paquete.tipo === 'Individual'"
          class="my-2"
        >
          <h4 class="user-select-none">Datos de recepción</h4>
          <div class="row">
            <p class="col-12 col-sm-6 col-xl-3 mb-2 pr-xl-0">
              <small class="text-muted user-select-none d-block">
                Receptor
              </small>
              <span :class="{'text-muted font-italic user-select-none': !paquete.receptor}">
                {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
              </span>
            </p>
            <p class="col-12 col-sm-6 col-xl-3 mb-2">
              <small class="text-muted user-select-none d-block">
                Tracking
              </small>
              <span :class="{'text-muted font-italic user-select-none': !paquete.tracking}">
                {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
              </span>
            </p>
            <p v-if="paquete.observacion" class="col-12 col-xl-6 mb-2">
              <small class="text-muted user-select-none d-block">
                Observación
              </small>
              {{ paquete.observacion }}
            </p>
          </div>
        </div>
        <!-- Instrucciones de envío -->
        <h4
          class="cursor-pointer user-select-none mt-2"
          @click="mostrarModalSeleccionarDireccion = true;"
        >
          <font-awesome-icon
            icon="edit"
            size="sm"
          />
          Instrucciones de envío
        </h4>
        <div class="row">
          <div class="col-12 col-md-8 col-xl-6">
            <div class="row">
              <p class="col-12 col-md-6 mb-2">
                <small class="text-muted user-select-none d-block">
                  Envío
                </small>
                <font-awesome-icon
                  v-if="paquete.envio"
                  :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                  class="texto-terciario"
                />
                <span :class="{'text-muted font-italic user-select-none': !paquete.envio}">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="col-12 mb-2 pr-md-0">
                <small class="text-muted user-select-none d-block">
                  Dirección de envío:
                </small>
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic user-select-none">
                  Sin dirección de envío
                </span>
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-xl-6">
            <small class="text-muted user-select-none d-block">
              Persona que recibe
            </small>
            <div
              v-if="paquete.direccionDestino
                && paquete.direccionDestino.recibe"
            >
              <span :class="{'text-muted font-italic user-select-none': !paquete.direccionDestino.recibe}">
                {{ paquete.direccionDestino.recibe
                  ? paquete.direccionDestino.recibe.nombre
                  : "Sin especificar"
                }}
              </span>
              <a
                v-if="paquete.direccionDestino.recibe.telefonos
                  &&paquete.direccionDestino.recibe.telefonos.length"
                target="_blank"
                :href="`tel:+${paquete.direccionDestino.recibe.telefonos[0].numero}`"
                class="d-block text-break"
              >
                +{{ paquete.direccionDestino.recibe.telefonos[0].numero }}
              </a>
              <span
                v-else
                class="d-block text-muted font-italic user-select-none"
              >
                Sin teléfono de contacto
              </span>
            </div>
            <p
              v-else
              class="text-muted font-italic user-select-none mb-2"
            >
              Sin especificar
            </p>
          </div>
        </div>
        <!-- Tabla de paquetes cuando paquete.tipo === 'Reempaque' -->
        <div
          v-if="paquete.tipo === 'Reempaque' && paquete.contiene"
          class="row mt-3 mx-0"
        >
          <h5 class="col-12 pl-0 user-select-none">Paquetes contenidos</h5>
          <ul data-table="sticky" class="table fs-08 col-12 col-md-8 col-xl-6">
            <li class="encabezado row align-items-end">
              <div class="col-auto fecha">
                Fecha
              </div>
              <div class="col">Detalles</div>
            </li>
            <li
              v-for="paqueteContiene in paquete.contiene"
              :key="`paquete-${paqueteContiene.id}`"
              class="contenido normal row align-items-center"
              @click="$router.push(`/paquetes/${paqueteContiene.id}`)"
            >
            <div class="col-auto fecha">
              <span class="d-block">
                {{ formatoFecha(paqueteContiene.fecha.formatted).ddmm }}
              </span>
              {{ formatoFecha(paqueteContiene.fecha.formatted).yyyy }}
            </div>
            <div class="col">
                Receptor:
                <span :class="paqueteContiene.receptor ? '' : 'text-muted user-select-none'">
                  {{ paqueteContiene.receptor ? paqueteContiene.receptor : "Sin especificar" }}
                </span>
              <p class="mb-0">
                Tracking:
                <span :class="paqueteContiene.tracking ? '' : 'text-muted user-select-none'">
                  {{ paqueteContiene.tracking ? paqueteContiene.tracking : "Sin especificar" }}
                </span>
              </p>
              <span
                v-if="paqueteContiene.observacion && paqueteContiene.observacion.length"
                class="d-block"
              >
                Observación: {{ paqueteContiene.observacion }}
              </span>
            </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Modal Seleccionar Dirección -->
    <ModalSeleccionarDireccion
      :mostrar-modal="mostrarModalSeleccionarDireccion"
      :paqueteSeleccionado="paquete ? paquete : {}"
      :clienteId="clienteId"
      @paqueteConDireccion="paquete = $event"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalSeleccionarDireccion = $event;"
    />
    <!-- Galería de fotos del paquete -->
    <mdb-modal
      centered
      elegant
      size="lg"
      :show="mostrarModalVerFotos"
      @close="mostrarModalVerFotos = false;"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          Galería de fotos
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="paquete && paquete.fotos && paquete.fotos.length">
          <ul class="listado-fotos">
            <li
              v-for="(foto, iFoto) in paquete.fotos"
              :key="iFoto"
              class="foto-contenedor"
            >
              <div class="foto">
                <img
                  v-lazy="`${endpointPublicBackend}${foto.url}`"
                  :alt="`Imagen nro ${iFoto + 1} del paquete`"
                  style="height: 100px"
                  @click="abrirGaleriaEn(iFoto)"
                >
              </div>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="vertical-centered vc-160px user-select-none"
        >
          <font-awesome-icon
            :icon="['fas', 'images']"
            class="fa-5x my-4"
          />
          <p class="h4-responsive text-center mb-4">
            No hay fotos del paquete
          </p>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <LightBox
      v-if="mostrarModalVerFotos"
      ref="lightbox"
      :media="fotosDelPaquete"
      :show-light-box="false"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>
<script>
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import CargandoVista from '@/components/CargandoVista.vue'
import obtenerFotosGql from '@/graphql/obtenerFotos.gql'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import LightBox from 'vue-image-lightbox'
import ModalSeleccionarDireccion from '@/components/ModalSeleccionarDireccion.vue'
import gql from 'graphql-tag'
import paqueteGql from '@/graphql/paquete.gql'
import { PaqueteEstatus, PaqueteTipoEmpaqueIconos, endpointPublicBackend } from '@/constantes/paquetes.js'
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from '@/constantes/consolidados.js'
import { formatearFecha, mostrarSoloDosDecimales } from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
import { leerPersonaId } from '@/utils/datosToken.js'

export default {
  name: 'Paquete',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    AlertaMensaje,
    CargandoVista,
    LightBox,
    ModalSeleccionarDireccion
  },
  data () {
    return {
      mostrarSoloDosDecimales,
      // Ver fotos
      mostrarModalVerFotos: false,
      // Seleccionar dirección
      mostrarModalSeleccionarDireccion: false,
      // Otros
      alertaMensaje: { contenido: '' },
      clienteId: '',
      PaqueteEstatus,
      PaqueteTipoEmpaqueIconos,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      paquete: {},
      endpointPublicBackend
    }
  },
  mounted () {
    this.clienteId = leerPersonaId()
  },
  computed: {
    esIndividual: function () {
      return !!(this.paquete && this.paquete.tipo === 'Individual')
    },
    fotosDelPaquete () {
      if (this.paquete && this.paquete.fotos && this.paquete.fotos.length > 0) {
        const endpoint = this.endpointPublicBackend
        return this.paquete.fotos.map((foto) =>
          ({
            thumb: `${endpoint}${foto.url}`,
            src: `${endpoint}${foto.url}`
          })
        )
      }
      return ['']
    }
  },
  methods: {
    // Funciones fotos
    abrirGaleriaEn (foto) {
      this.$refs.lightbox.showImage(foto)
    },
    obtenerFotosPaquete () {
      this.mostrarModalVerFotos = true
      this.$apollo.query({
        query: gql`${obtenerFotosGql}`,
        variables: {
          grafoId: this.$route.params.paqueteId
        },
        update: data => data.obtenerFotos,
        fetchPolicy: 'network-only'
      }).then(res => {
        this.paquete.fotos = res.data.obtenerFotos
      }).catch(() => {
        this.mostrarMensajeEnCasoDefault()
        this.mostrarModalVerFotos = false
      })
    },
    // Funcionalidad de alerta mensaje
    mostrarMensajeEnCasoDefault () {
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error'
      }
    },
    // Dar formato a fecha
    formatoFecha (fecha) {
      return formatearFecha(fecha)
    }
  },
  apollo: {
    paquete () {
      return {
        query: gql`
          ${paqueteGql}
        `,
        variables: {
          id: this.$route.params.paqueteId
        },
        update: (data) => data.Paquete[0],
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vista-detalles.paquete {
  .col-auto.fecha {width: 70px;}
}
</style>
